import { useCallback } from "react";

import {
  skipToken,
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import qs from "qs";

import { getStripeService } from "api/stripe-service";

import type {
  StripeCustomerSubscriptionType,
  StripeProductType,
} from "api/types/stripe";

interface UseStripeSubscription {
  subscription: UseQueryResult<StripeCustomerSubscriptionType>;
  product: UseQueryResult<StripeProductType>;
}

export const useStripeSubscription = (
  stripeCustomerId: string
): UseStripeSubscription => {
  const { data: session } = useSession();

  const stripeQueryFn = useCallback(
    <T extends unknown>(url: string): Promise<T> =>
      session?.accessToken
        ? getStripeService().getWithToken<T>(url, session?.accessToken)
        : null,
    [session?.accessToken]
  );

  const subscription = useQuery({
    queryKey: ["stripe-subscription", stripeCustomerId],
    queryFn: stripeCustomerId
      ? () =>
          stripeQueryFn<{ data: StripeCustomerSubscriptionType[] }>(
            `st/v1/subscriptions?${qs.stringify({
              customer: stripeCustomerId,
            })}`
          ).then((data) => data?.data?.[0] ?? null)
      : skipToken,
  });

  const product = useQuery({
    queryKey: [
      "stripe-product",
      subscription?.data?.items?.data?.[0]?.price?.product,
    ],
    queryFn: subscription?.data?.items?.data?.[0]?.price?.product
      ? () =>
          stripeQueryFn<StripeProductType>(
            `/st/v1/products/${subscription?.data?.items.data[0].price.product}`
          )
      : skipToken,
  });

  return { subscription, product };
};
