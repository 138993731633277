/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import axios, { AxiosRequestConfig } from "axios";

import { APIServiceClient } from "./api-service";

import type {
  ContactFormRequestBody,
  ContactFormType,
  CustomerShortProfile,
  ForgotPasswordRequestBody,
  LoginRequestBody,
  RegisterRequestBody,
  ResetPasswordRequestBody,
  SocialAuthRequestBody,
  SocialAuthType,
  Team,
  UserGroup,
  UserGroupWithPermissions,
  UserSettings,
} from "./types/user-service";
import type { User } from "next-auth";
import type { JWT } from "next-auth/jwt";
import type { UserAccountProps, UserModelInterface } from "utils/types/account";

export const cancelTokenSource = axios.CancelToken.source();

let userServiceClient: UserServiceClient | undefined;

/**
 * The UserServiceClient class creates an Axios instance for making HTTP requests to the
 * User microservice API, with methods for authentication, profile management, and miscellaneous tasks.
 *
 * TODO: Add types for all data models used in this service.
 * TODO: Replace all uses of "any" type.
 */
class UserServiceClient extends APIServiceClient {
  constructor(accessToken?: string, disableKeysToCamel?: boolean) {
    super({
      accessToken,
      baseURL: process.env.TPL_USERS_SERVICE,
      disabledKeysToCamel: disableKeysToCamel,
      config: { cancelToken: cancelTokenSource.token },
    });
  }

  auth() {
    return {
      login: async (
        data: LoginRequestBody,
        detectedIP: string
      ): Promise<User> =>
        await this.instance.post("auth/login/", data, {
          headers: {
            ...(detectedIP &&
              detectedIP !== "::1" && { "client-ip": detectedIP }),
          },
        }),
      register: async (
        data: RegisterRequestBody,
        detectedIP: string
      ): Promise<User> =>
        await this.instance.post("auth/register/", data, {
          headers: {
            ...(detectedIP &&
              detectedIP !== "::1" && { "client-ip": detectedIP }),
          },
        }),
      sendSocialAuthToken: async (
        data: SocialAuthRequestBody,
        social: SocialAuthType,
        detectedIP: string
      ): Promise<User> =>
        await this.instance.post(`social_auth/${social}/`, data, {
          headers: {
            ...(detectedIP &&
              detectedIP !== "::1" && { "client-ip": detectedIP }),
          },
        }),
      forgotPassword: async (data: ForgotPasswordRequestBody) =>
        await this.instance.post("auth/password_reset/", data),
      resetPassword: async (data: ResetPasswordRequestBody) =>
        await this.instance.post("auth/password_reset/confirm/", data),
      refreshAccessToken: async (
        refreshToken: string
      ): Promise<{ access: string }> =>
        await this.instance.post("auth/token/refresh/", {
          refresh: refreshToken,
        }),
      markRegisterationComplete: async () =>
        await this.instance.post("auth/registration-complete/", null, {
          withCredentials: true,
        }),
      logout: async (refreshToken: string, accessToken: string): Promise<JWT> =>
        await this.instance.post(
          "auth/logout/",
          { refresh: refreshToken },
          {
            /**
             * Passing authoization token manually as NextAuth session
             * has ended, so this.getToken() does not return token
             */
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        ),
      communityEvent: async () =>
        await this.instance.post("auth/customer-community-event/ ", null, {
          withCredentials: true,
        }),
      sendCustomJoinInfo: async (data) =>
        await this.instance.put("auth/custom-join-registration/ ", data, {
          withCredentials: true,
        }),
    };
  }

  profile(accessToken?: string) {
    return {
      getUser: async (
        config?: AxiosRequestConfig
      ): Promise<UserModelInterface> =>
        this.getUserModel(
          await this.instance.get("auth/customer-profile/", {
            ...(accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : {
                  withCredentials: true,
                }),
            ...config,
          })
        ),
      updateUser: async (data: any): Promise<UserAccountProps> =>
        await this.instance.put("auth/customer-profile/", data, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }),
      generateSmsToken: async (data: any) =>
        await this.instance.post("auth/password_reset/sms-token/", data, {
          withCredentials: true,
        }),
      generateEmailToken: async ({
        email,
        isStudent = false,
      }: {
        email: string;
        isStudent?: boolean;
      }) =>
        await this.instance.post(
          "auth/password_reset/email-token/",
          {
            email,
            is_student: isStudent,
          },
          {
            withCredentials: true,
          }
        ),
      verifyEmail: async (data: any) =>
        await this.instance.post("auth/password_reset/email-confirm/", data, {
          withCredentials: true,
        }),
      verifySms: async (data: any) =>
        await this.instance.post("auth/password_reset/sms-confirm/", data, {
          withCredentials: true,
        }),
      changePassword: async (data: any) =>
        await this.instance.patch("auth/change-password/", data, {
          withCredentials: true,
        }),
      sendReferralCode: async (data: any) =>
        await this.instance.put("auth/customer-referral/", data, {
          withCredentials: true,
        }),
      setUserTeam: async (data: any) =>
        await this.instance.put("auth/team/", data, {
          withCredentials: true,
        }),
      removeUserAvatar: async (): Promise<UserAccountProps> =>
        await this.instance.delete("auth/customer-profile/", {
          withCredentials: true,
        }),
      verifyUserName: async (
        username: string
      ): Promise<{ available: boolean }> =>
        await this.instance.get(`auth/verify-username/${username}/`, {
          withCredentials: true,
        }),
      getProfilesByIds: async (
        ids: string[]
      ): Promise<CustomerShortProfile[]> => {
        const params = new URLSearchParams();
        ids.forEach((id: string) => params.append("id", id));

        return await this.instance.get(`/auth/customer-profiles/`, {
          params: params,
        });
      },
      searchUsersProfiles: async (
        searchText: string,
        excludeSelf: boolean
      ): Promise<CustomerShortProfile[]> => {
        return await this.instance.get(`/auth/customer-profiles/`, {
          withCredentials: true,
          params: {
            search: searchText,
            exclude_self: excludeSelf,
          },
        });
      },
      followUser: async (userId: string): Promise<[]> => {
        return await this.instance.post(
          `/auth/customer-follower/`,
          {
            following: userId,
          },
          {
            withCredentials: true,
          }
        );
      },
      unfollowUser: async (customerId: string): Promise<[]> => {
        return await this.instance.delete(
          `/auth/customer-follower/${customerId}`,
          {
            withCredentials: true,
          }
        );
      },
      getFollowers: async (): Promise<Array<string>> => {
        return await this.instance.get(`/auth/customer-follower/`, {
          withCredentials: true,
        });
      },
    };
  }

  settings(accessToken?: string) {
    return {
      get: async (config?: AxiosRequestConfig): Promise<UserSettings> =>
        await this.instance.get("auth/customer-settings/", {
          ...(accessToken
            ? {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            : {
                withCredentials: true,
              }),
          ...config,
        }),
      update: async (data: any): Promise<UserSettings> =>
        await this.instance.put("auth/customer-settings/", data, {
          withCredentials: true,
        }),
    };
  }

  permissions(accessToken?: string) {
    return {
      getUserPermissions: async (
        config?: AxiosRequestConfig
      ): Promise<UserGroupWithPermissions> =>
        this.getUserGroupWithPermissionsModel(
          await this.instance.get("auth/user-group/", {
            ...(accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : {
                  withCredentials: true,
                }),
            ...config,
          })
        ),
    };
  }

  postInteraction() {
    return {
      get: async <T>(endpoint: string): Promise<T> =>
        this.instance.get(endpoint, { withCredentials: true }),
      setPostInteraction: async (data: any) =>
        await this.instance.post("/strapi/post-interaction", data, {
          withCredentials: true,
        }),
    };
  }

  misc() {
    return {
      fetchTeamList: async (): Promise<Team[]> =>
        await this.instance.get("auth/teams/"),
      submitContactForm: async (
        data: ContactFormRequestBody,
        form: ContactFormType
      ) =>
        await this.instance.post(
          `${form === "contactUs" ? "contact_us/" : "work_with_us/"}`,
          { ...data, company: data.company ?? null }
        ),
      verifyCustomJoinCode: async (
        data
      ): Promise<{
        isCustomer: boolean;
        isStudent: boolean;
        productId: boolean;
        subscriptionStatus: boolean;
        isValidCoupon: boolean;
      }> => await this.instance.post(`auth/custom-join-verification`, data),
      fetchWriterUserName: async (
        email: string
      ): Promise<{ username: string }> =>
        await this.instance.get(`auth/other-profile-email/${email}/`, {
          withCredentials: true,
        }),
    };
  }

  getUserModel(userData: UserAccountProps): UserModelInterface {
    return {
      ...userData,

      userType: {
        isStudent: userData?.type?.type === "student",
        isCustomer: userData?.type?.type === "customer",
      },
    };
  }

  getUserGroupWithPermissionsModel(
    userGroupsData: UserGroup[]
  ): UserGroupWithPermissions {
    return {
      ...userGroupsData[0],

      permissions: userGroupsData.reduce(
        (permissionsAccumulator, userGroupData) =>
          userGroupData.subFeatures.reduce(
            (accumulator, subFeature) => ({
              ...accumulator,
              [subFeature.permissionSlug]: true,
            }),
            permissionsAccumulator
          ),
        {}
      ),
    };
  }
}

export const getUserService = (
  accessToken?: string,
  disabledKeysToCamel?: boolean
) => {
  if (accessToken || disabledKeysToCamel) {
    return new UserServiceClient(accessToken, disabledKeysToCamel);
  }

  const _userServiceClient = userServiceClient ?? new UserServiceClient();

  if (!userServiceClient) userServiceClient = _userServiceClient;

  return _userServiceClient;
};

/**
 * It creates a UserService Client instance and returns it
 */
export const useUserService = () => {
  const store = useMemo(() => getUserService(), []);
  return store;
};
