import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import reduce from "lodash/reduce";
import { useSession } from "next-auth/react";
import useSWRImmutable from "swr/immutable";

import { getPostService } from "api/post-service";
import { getUserService } from "api/user-service";
import { identifyUser } from "lib/segment";
import { useStripeSubscription } from "utils/hooks/stripe/useStripeSubscription";

import { useTeamsContext } from "./TeamsContext";
import {
  UserPermission,
  type UserModelInterface,
  type UserSettings,
} from "../types/account";

import type { HighestModerationPosition } from "api/types/post-service";
import type {
  UserGroup,
  UserGroupWithPermissions,
} from "api/types/user-service";

interface UserContextProps {
  userDetails: UserModelInterface;
  userStatus: "authenticated" | "loading" | "unauthenticated";
  followers: { [key: string]: boolean };
  userSettings: UserSettings;
  storeUserSettings: (data: Partial<UserSettings>) => void;
  storeUserDetails: (data: Partial<UserModelInterface>) => void;
  userGroupWithPermissions: UserGroupWithPermissions;
  storeUserGroupWithPermissions: (
    data: Partial<UserGroupWithPermissions>
  ) => void;
  updateUserDetails: () => Promise<UserModelInterface>;
  updateUserGroupWithPermissions: () => Promise<UserGroupWithPermissions>;
  updateHighestModeratorPosition: () => Promise<HighestModerationPosition>;
  updateFollowers: () => Promise<Array<string>>;
  highestModeratorPosition: { position: string; loading: boolean };
  userHasPermission: (permission: UserPermission) => boolean;
  updateUserSettings: () => Promise<UserSettings>;
}

// Creating the user context
export const UserContext = createContext<UserContextProps>(
  null as UserContextProps
);

export const UserContextProvider = ({ children }: PropsWithChildren) => {
  const { status, data: session } = useSession();
  const { getTeamByKey } = useTeamsContext();

  const userServiceFetcher = useCallback(
    <T extends unknown>({
      url,
      disableKeysToCamel,
    }: {
      url: string;
      disableKeysToCamel?: boolean;
    }): Promise<T> =>
      getUserService(null, disableKeysToCamel).getWithToken<T>(
        url,
        session?.accessToken
      ),
    [session?.accessToken]
  );

  const communityServiceFetcher = useCallback(
    <T extends unknown>({ url }: { url: string }): Promise<T> =>
      getPostService().getWithToken<T>(url, session?.accessToken),
    [session?.accessToken]
  );

  const {
    data: details,
    isLoading: loadingDetails,
    mutate: refetchDetails,
  } = useSWRImmutable(
    session?.user?.id
      ? { url: "/auth/customer-profile/", userId: session?.user?.id }
      : null,
    (key) =>
      userServiceFetcher<UserModelInterface>(key).then(
        getUserService().getUserModel
      )
  );

  const {
    data: permissions,
    isLoading: loadingPermissions,
    mutate: refetchPermissions,
  } = useSWRImmutable(
    session?.user?.id
      ? {
          url: "/auth/user-group/",
          userId: session?.user?.id,
          userGroup: details?.groups?.[0]?.id,
        }
      : null,
    (key) =>
      userServiceFetcher<UserGroup[]>(key).then(
        getUserService().getUserGroupWithPermissionsModel
      )
  );

  const {
    data: moderatorPosition,
    isLoading: moderatorPositionLoading,
    mutate: updateHighestModeratorPosition,
  } = useSWRImmutable(
    session?.user?.id
      ? {
          url: "/communities/api/v1/highest_moderator_position",
          userId: session?.user?.id,
        }
      : null,
    (key) => communityServiceFetcher<HighestModerationPosition>(key)
  );

  const { data: followerIds, mutate: updateFollowers } = useSWRImmutable(
    session?.user?.id
      ? { url: "/auth/customer-follower/", userId: session?.user?.id }
      : null,
    (key) => userServiceFetcher<Array<string>>(key)
  );

  const { data: settings, mutate: refetchUserSettings } = useSWRImmutable(
    session?.user?.id
      ? { url: "/auth/customer-settings/", userId: session?.user?.id }
      : null,
    (key) =>
      userServiceFetcher<UserSettings>({ ...key, disableKeysToCamel: true })
  );

  const followers = useMemo(
    () => reduce(followerIds, (acc, id) => ({ ...acc, [id]: true }), {}),
    [followerIds]
  );

  const highestModeratorPosition = useMemo(
    () => ({
      position: moderatorPosition?.highestModeratorPosition,
      loading: moderatorPositionLoading,
    }),
    [moderatorPosition?.highestModeratorPosition]
  );

  const userId = useMemo(
    () => details?.id || session?.user?.id,
    [details, session?.user]
  );

  const teamId = useMemo(
    () =>
      details?.customerTeam?.[0]?.team ||
      (session?.user?.customerTeam
        ? getTeamByKey(session?.user?.customerTeam, "shortName")?.id
        : null),
    [details, session?.user]
  );

  const [userDetails, setUserDetails] = useState<UserModelInterface>(null);
  const [userGroupWithPermissions, setUserGroupWithPermissions] =
    useState<UserGroupWithPermissions>(null);
  const [userSettings, setUserSettings] = useState<UserSettings>(null);

  const userStatus = useMemo(
    () =>
      status === "unauthenticated"
        ? status
        : loadingDetails ||
          loadingPermissions ||
          !userDetails ||
          !userGroupWithPermissions
        ? "loading"
        : "authenticated",
    [
      status,
      loadingDetails,
      loadingPermissions,
      userDetails,
      userGroupWithPermissions,
    ]
  );

  const storeUserDetails = useCallback(
    (data: Partial<UserModelInterface>) => {
      if (status === "unauthenticated" || !data) {
        setUserDetails(null);
      } else {
        setUserDetails(
          (details) => ({ ...details, ...data } as UserModelInterface)
        );

        userId && identifyUser(userId, data);
      }
    },
    [status]
  );

  const storeUserGroupWithPermissions = useCallback(
    (data: Partial<UserGroupWithPermissions>) => {
      if (status === "unauthenticated" || !data) {
        setUserGroupWithPermissions(null);
      } else {
        setUserGroupWithPermissions(
          (details) => ({ ...details, ...data } as UserGroupWithPermissions)
        );

        userId && identifyUser(userId, { subscription: data.group.name });
      }
    },
    [status]
  );

  const storeUserSettings = useCallback(
    (data: Partial<UserSettings>) => {
      if (status === "unauthenticated") setUserSettings(null);
      data
        ? setUserSettings(
            (settings) => ({ ...settings, ...data } as UserSettings)
          )
        : setUserSettings(null);
    },
    [status]
  );

  const userHasPermission = useCallback(
    (permission: UserPermission) =>
      userGroupWithPermissions?.permissions &&
      permission in userGroupWithPermissions.permissions &&
      userGroupWithPermissions.permissions[permission] === true,
    [userGroupWithPermissions]
  );

  useEffect(() => {
    if (details) storeUserDetails(details);
  }, [details]);

  useEffect(() => {
    if (settings) storeUserSettings(settings);
  }, [settings]);

  useEffect(() => {
    if (permissions) storeUserGroupWithPermissions(permissions);
  }, [permissions]);

  useEffect(() => {
    if (status === "unauthenticated") {
      storeUserDetails(null);
      storeUserGroupWithPermissions(null);
    }
  }, [status]);

  const { subscription } = useStripeSubscription(details?.stripeCustomerId);

  useEffect(() => {
    if (
      subscription.isFetched &&
      subscription.data &&
      details?.customerSubscription?.[0]?.status?.status === "Free Account"
    ) {
      refetchDetails();
    }
  }, [subscription]);

  const updateUserDetails = () => refetchDetails();

  const updateUserGroupWithPermissions = () => refetchPermissions();

  const updateUserSettings = () => refetchUserSettings();

  return (
    <UserContext.Provider
      value={{
        userDetails,
        storeUserDetails,
        userGroupWithPermissions,
        storeUserGroupWithPermissions,
        updateUserDetails,
        updateUserGroupWithPermissions,
        updateHighestModeratorPosition,
        userStatus,
        highestModeratorPosition,
        userHasPermission,
        followers,
        updateFollowers,
        userSettings,
        storeUserSettings,
        updateUserSettings,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Make useUserContext Hook to easily use our context throughout the application
export const useUserContext = () => {
  const result = useContext(UserContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }

  return result;
};
