/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

import { convertStrToBool } from "utils/helpers/stringUtils";

import { APIServiceClient } from "./api-service";
import {
  RawStripeProductType,
  StripeProductType,
  StripePriceType,
  StripeProductFunctionalityObjType,
  StripeCheckoutSession,
  StripeCustomerSubscriptionType,
  StripeUpcomingInvoiceType,
  StripePromocodeType,
  StripePaymentMethodType,
  UserGroupPlanType,
  ScheduledSubscriptionType,
  StripeCustomer,
} from "./types/stripe";
import { Feature } from "./types/user-service";

export const cancelTokenSource = axios.CancelToken.source();

let stripeServiceClient: StripeServiceClient | undefined;

/**
 * Processing Stripe product for a more convenient interaction
 *
 * @param   {RawStripeProductType}  stripeProduct  Stripe product
 *
 * @return  {StripeProductType}                 Stripe product
 */
function stripeProductProcessing(
  stripeProduct: RawStripeProductType
): StripeProductType {
  return {
    ...stripeProduct,
    metadata: {
      functionalityListJson: stripeProduct.metadata.functionalityListJson,
      markerText: stripeProduct.metadata.markerText,
      order: Number(stripeProduct.metadata.order),
      mobileOrder: Number(stripeProduct.metadata.mobileOrder),
      onlyForVerifiedStudents: stripeProduct.metadata.onlyForVerifiedStudents
        ? convertStrToBool(stripeProduct.metadata.onlyForVerifiedStudents)
        : false,
      disabled: stripeProduct.metadata.disabled
        ? convertStrToBool(stripeProduct.metadata.disabled)
        : false,
      shortFunctionalityListJson:
        stripeProduct.metadata.shortFunctionalityListJson,
      shortFunctionalityList: stripeProduct.metadata.shortFunctionalityListJson
        ? JSON.parse(stripeProduct.metadata.shortFunctionalityListJson)
        : null,
    },
  };
}

class StripeServiceClient extends APIServiceClient {
  constructor(accessToken?: string) {
    super({
      accessToken,
      baseURL: process.env.TPL_USERS_SERVICE,
    });
  }

  /**
   * It fetches Stripe products list with Stripe's prices list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[StripeProductType[]]}  Stripe products list with Stripe's prices list
   */
  async getStripeProductsListWithPrices(
    config?: AxiosRequestConfig
  ): Promise<StripeProductType[]> {
    const [stripeProducts, stripePrices, stripeProductsFunctionalityLists] =
      await Promise.all([
        this.getStripeProductsList(config),
        this.getStripePricesList(config),
        this.getStripeProductFunctionalityLists(config),
      ]);

    return stripeProducts
      .map((stripeProduct) => ({
        ...stripeProduct,
        metadata: {
          ...stripeProduct.metadata,
          functionalityList: stripeProductsFunctionalityLists[stripeProduct.id],
        },
        default_price: stripePrices.find(
          ({ id }) => id === stripeProduct.defaultPrice
        ),
        productPrices: stripePrices.filter(
          ({ product }) => product === stripeProduct.id
        ),
      }))
      .sort(
        ({ metadata: { order: orderA } }, { metadata: { order: orderB } }) =>
          (orderA as number) - (orderB as number)
      );
  }

  /**
   * It fetches Stripe's products list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[StripeProductType[]]}   Stripe's products list
   */
  async getStripeProductsList(
    config?: AxiosRequestConfig
  ): Promise<StripeProductType[]> {
    return (
      (
        await this.instance.get("/st/v1/products", {
          withCredentials: true,
          ...config,
        })
      ).data || []
    ).map(stripeProductProcessing);
  }

  /**
   * It fetches Stripe's product
   *
   * @param   {string}               productID       Stripe product ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {[StripeProductType]}   Stripe's product
   */
  async getStripeProductByID(
    productID: string,
    config?: AxiosRequestConfig
  ): Promise<StripeProductType> {
    return stripeProductProcessing(
      await this.instance.get(`/st/v1/products/${productID}`, {
        withCredentials: true,
        ...config,
      })
    );
  }

  /**
   * It fetches Stripe's prices list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[StripePriceType[]]}   Stripe's prices list
   */
  async getStripePricesList(
    config?: AxiosRequestConfig
  ): Promise<StripePriceType[]> {
    return (
      await this.instance.get("/st/v1/prices", {
        withCredentials: true,
        ...config,
      })
    ).data;
  }

  /**
   * It fetches Stripe's price
   *
   * @param   {string}               priceID       Stripe price ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {[StripePriceType]}   Stripe's price
   */
  getStripePriceByID(
    priceID: string,
    config?: AxiosRequestConfig
  ): Promise<StripePriceType> {
    return this.instance.get(`/st/v1/prices/${priceID}`, {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches all Stripe product functionality list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {Promise<{ (key: string): StripeProductFunctionalityObjType[]; }}   All Stripe product functionality list
   */
  getStripeProductFunctionalityLists(config?: AxiosRequestConfig): Promise<{
    (key: string): StripeProductFunctionalityObjType[];
  }> {
    return this.instance.get("/st/v1/plan_permissions", {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches Stripe product functionality list Stripe Checkout Session
   *
   * @param   {string}               priceId      Stripe price ID
   * @param   {string}               successUrl   Success url
   * @param   {string}               cancelUrl    Cancel url
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {Promise<StripeCheckoutSession>}    Stripe Checkout Session
   */
  async getStripeCheckoutSession(
    priceId,
    successUrl: string = location.href,
    cancelUrl: string = location.href,
    config?: AxiosRequestConfig
  ): Promise<StripeCheckoutSession> {
    return this.instance.post(
      "/st/v1/product_page",
      {
        price: priceId,
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        ...config,
      }
    );
  }

  /**
   * It fetches Stripe product functionality list Stripe Save Payment Setup Session
   *
   * @param   {string}               successUrl   Success url
   * @param   {string}               cancelUrl    Cancel url
   *
   * @return  {Promise<StripeCheckoutSession>}    Stripe Checkout Session
   */
  async getStripeSavePaymentSession(
    successUrl: string = location.href,
    cancelUrl: string = location.href
  ): Promise<StripeCheckoutSession> {
    return this.instance.post(
      "/st/v1/setup_payment",
      {
        success_url: successUrl,
        cancel_url: cancelUrl,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  }

  /**
   * It fetches Stripe customer
   *
   * @param   {string}               customerID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {Promise<StripeCustomer>}    Stripe customer
   */
  async getStripeCustomerByID(
    customerID: string,
    config?: AxiosRequestConfig
  ): Promise<StripeCustomer> {
    return await this.instance.get(`/st/v1/customers/${customerID}`, {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches Stripe customer subscriptions
   *
   * @param   {string}               customerID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {Promise<StripeCustomerSubscriptionType[]>}    Stripe customer subscriptions
   */
  async getStripeSubscriptionsByCustomerID(
    customerID: string,
    config?: AxiosRequestConfig
  ): Promise<StripeCustomerSubscriptionType[]> {
    return (
      await this.instance.get(
        `st/v1/subscriptions?${qs.stringify({
          customer: customerID,
        })}`,
        {
          withCredentials: true,
          ...config,
        }
      )
    )?.data;
  }

  /**
   * It fetches scheduled Stripe customer subscriptions
   *
   * @param   {string}               customerID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {Promise<ScheduledSubscriptionType[]>}    Scheduled Stripe customer subscriptions
   */
  async getStripeScheduledSubscriptionsByCustomerID(
    customerID: string,
    config?: AxiosRequestConfig
  ): Promise<ScheduledSubscriptionType[]> {
    return (
      await this.instance.get(
        `st/v1/subscription_schedules?${qs.stringify({
          customer: customerID,
        })}`,
        {
          withCredentials: true,
          ...config,
        }
      )
    )?.data;
  }

  /**
   * It fetches Stripe customer upcoming invoice
   *
   * @param   {string}               customerID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config          Axios config object
   *
   * @return  {Promise<StripeUpcomingInvoiceType>}    Stripe customer upcoming invoice
   */
  async getStripeUpcomingInvoiceByCustomerID(
    customerID: string,
    config?: AxiosRequestConfig
  ): Promise<StripeUpcomingInvoiceType> {
    return await this.instance.get(
      `st/v1/invoices/upcoming?${qs.stringify({
        customer: customerID,
      })}`,
      {
        withCredentials: true,
        ...config,
      }
    );
  }

  /**
   * It fetches Stripe customer payment method
   *
   * @param   {string}               paymentMethodId      Stripe payment method ID
   * @param   {AxiosRequestConfig}   config               Axios config object
   *
   * @return  {Promise<StripePaymentMethodType>}    Stripe customer upcoming invoice
   */
  async getStripePaymentMethodByID(
    paymentMethodID: string,
    config?: AxiosRequestConfig
  ): Promise<StripePaymentMethodType> {
    return await this.instance.get(`st/v1/payment_methods/${paymentMethodID}`, {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches all Stripe customer payment methods
   *
   * @param   {AxiosRequestConfig}   config               Axios config object
   *
   * @return  {Promise<StripePaymentMethodType[]>}    Stripe customer upcoming invoice
   */
  async getAllStripePaymentMethods(
    config?: AxiosRequestConfig
  ): Promise<{ data: StripePaymentMethodType[] }> {
    return await this.instance.get(`st/v1/payment_methods`, {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * Cancel Stripe subscription
   *
   * @param   {string}               subscriptionID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config              Axios config object
   *
   * @return  {Promise<void>}    Promise
   */
  async cancelStripeSubscription(
    subscriptionID: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    return await this.instance.delete(
      `/st/v1/subscriptions/${subscriptionID}`,
      {
        withCredentials: true,
        ...config,
      }
    );
  }

  /**
   * Cancel Stripe subscription
   *
   * @param   {string}               scheduledSubscriptionID      Stripe customer ID
   * @param   {AxiosRequestConfig}   config              Axios config object
   *
   * @return  {Promise<void>}    Promise
   */
  async cancelStripeScheduledSubscription(
    scheduledSubscriptionID: string,
    config?: AxiosRequestConfig
  ): Promise<void> {
    return await this.instance.post(
      `/st/v1/subscription_schedules/${scheduledSubscriptionID}/cancel`,
      {},
      {
        withCredentials: true,
        ...config,
      }
    );
  }

  /**
   * Get special Stripe promocode for current user
   *
   * @param   {AxiosRequestConfig}    config              Axios config object
   *
   * @return  {Promise<StripePromocodeType>}    Stripe promocode
   */
  async getSpecialStripePromocodeForCurrentUser(
    config?: AxiosRequestConfig
  ): Promise<StripePromocodeType> {
    return await this.instance.get("/st/v1/promotion_code", {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * Cancel Stripe subscription
   *
   * @param   {string}               subscriptionID      Stripe customer ID
   * @param   {
   *   priceID?: string;             priceID         Stripe promocode ID
   *   promocodeID: string;          promocodeID         Stripe promocode ID
   * }
   * @param    {AxiosRequestConfig}  config              Axios config object
   *
   * @return  {Promise<void>}    Promise
   */
  async updateStripeSubscription(
    subscriptionID: string,
    {
      priceID,
      promocodeID,
    }: {
      priceID?: string;
      promocodeID?: string;
    },
    config?: AxiosRequestConfig
  ): Promise<StripeCustomerSubscriptionType> {
    return await this.instance.post(
      `/st/v1/subscriptions/${subscriptionID}`,
      {
        price: priceID,
        promo: promocodeID,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        ...config,
      }
    );
  }

  /**
   * It fetches Stripe products list with Stripe's prices list from user groups list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[StripeProductType[]]}  Stripe products list with Stripe's prices list
   */
  async getUserGroupPlanListWithStripeProductAndPrice(
    config?: AxiosRequestConfig
  ): Promise<UserGroupPlanType[]> {
    const [userGroupsPlans, stripeProducts, stripePrices] = await Promise.all([
      this.getUserGroupsPlanList(config),
      this.getStripeProductsList(config),
      this.getStripePricesList(config),
    ]);

    const stripeProductsWithPrice: StripeProductType[] = stripeProducts.map(
      (stripeProduct) =>
        ({
          ...stripeProduct,
          defaultPrice: stripePrices.find(
            ({ id }) => id === stripeProduct.defaultPrice
          ),
          productPrices: stripePrices.filter(
            ({ product }) => product === stripeProduct.id
          ),
        } as StripeProductType)
    );

    return userGroupsPlans
      .map(
        (userGroup) =>
          ({
            ...userGroup,
            product: {
              ...stripeProductsWithPrice.find(({ id }) =>
                userGroup.productId.includes(id)
              ),
              productPrices: stripePrices.filter(({ product }) =>
                userGroup.productId.includes(product)
              ),
            } as StripeProductType,
          } as UserGroupPlanType)
      )
      .filter(({ group }) => !!group?.content)
      .sort(
        (
          {
            group: {
              content: { order: orderA },
            },
          },
          {
            group: {
              content: { order: orderB },
            },
          }
        ) => orderA - orderB
      );
  }

  /**
   * It fetches User groups plans
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[UserGroupPlanType[]]}                   User groups plans
   */
  async getUserGroupsPlanList(
    config?: AxiosRequestConfig
  ): Promise<UserGroupPlanType[]> {
    return await this.instance.get("/st/v1/group_plan", {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches features list
   *
   * @param   {AxiosRequestConfig}      config          Axios config object
   *
   * @return  {[UserGroupPlanType[]]}                   Features list
   */
  async getFeaturesList(config?: AxiosRequestConfig): Promise<Feature[]> {
    return await this.instance.get("/st/v1/features", {
      withCredentials: true,
      ...config,
    });
  }

  /**
   * It fetches features list
   *
   * @param   {string}      paymentId          Payment Id
   *
   * @return  {Promise<StripePaymentMethodType>}                   Features list
   */
  async makePaymentMethodDefault(
    paymentId: string
  ): Promise<StripePaymentMethodType> {
    return await this.instance.put(
      `/st/v1/payment_methods/${paymentId}`,
      null,
      {
        withCredentials: true,
      }
    );
  }

  /**
   * It deletes the provided payment method
   *
   * @param   {string}      paymentId          Payment Id
   *
   * @return  {Promise<StripePaymentMethodType>}                   Features list
   */
  async detachPaymentMethod(
    paymentId: string
  ): Promise<StripePaymentMethodType> {
    return await this.instance.post(
      `/st/v1/payment_methods/${paymentId}/detach`,
      null,
      {
        withCredentials: true,
      }
    );
  }
}

export const getStripeService = (accessToken?: string) => {
  if (accessToken) {
    return new StripeServiceClient(accessToken);
  }

  const _stripeServiceClient = stripeServiceClient ?? new StripeServiceClient();

  if (!stripeServiceClient) stripeServiceClient = _stripeServiceClient;

  return _stripeServiceClient;
};

/**
 * It creates a StripeService Client instance and returns it
 */
export const useStripeService = () => {
  const store = useMemo(() => getStripeService(), []);
  return store;
};
