/**
 * Convert string to boolean
 *
 * @param   {string}  str  String
 *
 * @return  {boolean} Boolean
 */
export const convertStrToBool = (str: string): boolean => {
  return /true/i.test(str);
};

/**
 * Pluralize any string based on provided count
 *
 * @param   {string}   singularWord  String
 * @param   {string}   pluralWord  String
 * @param   {string}   count  String
 *
 * @return  {string}  sigular or plural value
 */
export const pluralize = (
  singularWord: string,
  pluralWord: string,
  count: number
) => {
  return count === 1 ? singularWord : pluralWord;
};
