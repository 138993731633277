/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";

import axios, { AxiosRequestConfig } from "axios";

import { COMMENT_LEVELS, LEAF_COMMENTS } from "utils/helpers/constants";
import {
  CustomerMuteInfo,
  CustomerMuteResults,
  PostTypes,
  BanUserRequest,
} from "utils/types/post";

import { APIServiceClient } from "./api-service";

import type {
  CommunitiesResult,
  ArticlePostResult,
  PostsResult,
  ReactionRequestBody,
  ReactionResult,
  ReactionsResult,
  SubCategoriesResult,
  TagsResult,
  AppealDetails,
  PaginatedResult,
} from "./types/post-service";
import type {
  Post,
  ArticlePost,
  UploadImageType,
  Comments,
  Comment,
  Poll,
  Polls,
  SubCategory,
  CustomerBannedResult,
  UnbannedCommunitiesResult,
  CustomerProfile,
  ModeratorsLogsResult,
  ModerationLogDetail,
  ModeratorPermissions,
  ActiveBan,
} from "utils/types/post";

export const API_URL = process.env.TPL_POSTS_SERVICE;

export const cancelTokenSource = axios.CancelToken.source();

let postServiceClient: PostServiceClient | undefined;

/**
 * The PostServiceClient class creates an Axios instance for making HTTP requests to the
 * Community microservice API, with methods for authentication, profile management, and miscellaneous tasks.
 *
 * TODO: Add types for all data models used in this service.
 * TODO: Replace all uses of "any" type.
 */
class PostServiceClient extends APIServiceClient {
  constructor(accessToken?: string) {
    super({
      accessToken,
      baseURL: process.env.TPL_POSTS_SERVICE,
      config: { cancelToken: cancelTokenSource.token },
    });
  }

  posts() {
    return {
      createPost: async (data: any): Promise<Post> =>
        await this.instance.post("posts/api/v1/posts/", data, {
          withCredentials: true,
        }),
      createArticlePost: async (data: any): Promise<ArticlePost> =>
        await this.instance.post("posts/api/v1/post_articles/", data, {
          withCredentials: true,
        }),
      updatePost: async (data: any, postId: string): Promise<Post> =>
        await this.instance.put(`posts/api/v1/posts/${postId}/`, data, {
          withCredentials: true,
        }),
      uploadImageS3: async (data: any): Promise<UploadImageType> =>
        await this.instance.post("s3_content/api/v1/s3_contents/", data, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }),
      getTagsList: async (): Promise<TagsResult> =>
        await this.instance.get("tags/api/v1/tags/", {
          withCredentials: true,
        }),
      getPostById: async <T>(id: string, params?: any): Promise<T> =>
        await this.instance.get(`posts/api/v1/posts/${id}/`, {
          ...(params && { params }),
          withCredentials: true,
        }),
      getPostBySlug: async (slug: string): Promise<Post> =>
        await this.instance.get(`posts/api/v1/posts/by_slug/${slug}/`),
      getPosts: async (
        page?: string,
        ordering?: string
      ): Promise<PostsResult> => {
        const params = {
          ...(page && { page }),
          ...(ordering && { ordering }),
          state: PostTypes.active,
        };
        const queryString = new URLSearchParams(params).toString();

        return await this.instance.get(
          `posts/api/v1/posts/?${queryString || ""}`
        );
      },
      getArticlePost: async (articleId: string): Promise<ArticlePostResult> =>
        await this.instance.get(
          `posts/api/v1/post_articles/${articleId}/?quiet=true`,
          { withCredentials: true }
        ),
      blockPost: async (data: any, postId: string): Promise<Post> =>
        await this.instance.put(`posts/api/v1/blocks/${postId}`, data, {
          withCredentials: true,
        }),
      unblockPost: async (postId: string): Promise<Post> =>
        await this.instance.delete(`posts/api/v1/blocks/${postId}`, {
          withCredentials: true,
        }),
      pinPost: async (postId: string): Promise<Post> =>
        await this.instance.put(
          `posts/api/v1/pins/${postId}`,
          {},
          {
            withCredentials: true,
          }
        ),
      unpinPost: async (postId: string): Promise<Post> =>
        await this.instance.delete(`posts/api/v1/pins/${postId}`, {
          withCredentials: true,
        }),
      markPremiumPost: async (postId: string): Promise<Post> =>
        await this.instance.put(
          `posts/api/v1/premium/${postId}`,
          {},
          {
            withCredentials: true,
          }
        ),
      unmarkPremiumPost: async (postId: string): Promise<Post> =>
        await this.instance.delete(`posts/api/v1/premium/${postId}`, {
          withCredentials: true,
        }),
      pauseNotifications: async (postId: string): Promise<void> =>
        this.instance.put(`posts/api/v1/pause_notification/${postId}`, null, {
          withCredentials: true,
        }),
      unpauseNotifications: async (postId: string): Promise<void> =>
        this.instance.delete(`posts/api/v1/pause_notification/${postId}`, {
          withCredentials: true,
        }),
    };
  }

  comments() {
    return {
      createComment: async (data: any): Promise<Comment> =>
        await this.instance.post("/comments/api/v1/comments/", data, {
          withCredentials: true,
        }),
      updateComment: async (data: any, commentId: string): Promise<Comment> =>
        await this.instance.put(
          `/comments/api/v1/comments/${commentId}/`,
          data,
          { withCredentials: true }
        ),
      blockComment: async (data: any, commentId: string): Promise<Comment> =>
        await this.instance.put(`comments/api/v1/blocks/${commentId}`, data, {
          withCredentials: true,
        }),

      unblockComment: async (commentId: string): Promise<Comment> =>
        await this.instance.delete(`comments/api/v1/blocks/${commentId}`, {
          withCredentials: true,
        }),

      getComments: async (postId: string, page: string): Promise<Comments> =>
        await this.instance.get(`comments/api/v1/comments/`, {
          params: {
            parent_id: postId,
            page: page,
            levels: COMMENT_LEVELS,
            leaf_comments: LEAF_COMMENTS,
            ordering: "-created_at",
            nested_ordering: "-created_at",
          },
          withCredentials: true,
        }),

      getComment: async <T>(
        commentId: string,
        levels?: number,
        pageNumber?: number,
        fields?: string,
        params?: any
      ): Promise<T> =>
        await this.instance.get(`comments/api/v1/comments/${commentId}/`, {
          params: {
            ...(fields && { fields }),
            ...(params && { ...params }),
            page: pageNumber,
            levels: levels || 0,
            leaf_comments: LEAF_COMMENTS,
            ordering: "-created_at",
            nested_ordering: "-created_at",
          },
          withCredentials: true,
        }),
      pauseNotifications: async (commentId: string): Promise<void> =>
        await this.instance.put(
          `comments/api/v1/pause_notification/${commentId}`,
          null,
          { withCredentials: true }
        ),
      unpauseNotifications: async (commentId: string): Promise<void> =>
        await this.instance.delete(
          `comments/api/v1/pause_notification/${commentId}`,
          { withCredentials: true }
        ),
    };
  }

  moderation(accessToken?: string) {
    return {
      getAppealDetails: async (
        moderationDecisionLog: string
      ): Promise<AppealDetails> =>
        await this.instance.get(
          `moderation/api/v1/appealable_moderation_decision_logs/${moderationDecisionLog}`,
          {
            ...(accessToken
              ? {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              : {
                  withCredentials: true,
                }),
          }
        ),
      createAppeal: async (data: any): Promise<any> =>
        await this.instance.post("/moderation/api/v1/appeals/", data, {
          withCredentials: true,
        }),

      getModeratorsList: async (communityId: string): Promise<any> =>
        await this.instance.get(
          `communities/api/v1/moderators?community=${communityId}`,
          {
            withCredentials: true,
          }
        ),
      updateModerator: async (data: any, communityId: string): Promise<any> =>
        await this.instance.patch(
          `/communities/api/v1/modifyModeratorsList?community=${communityId}`,
          data,
          {
            withCredentials: true,
          }
        ),
      getModeratorsLogs: async (path: string): Promise<ModeratorsLogsResult> =>
        await this.instance.get(path, {
          withCredentials: true,
        }),
      getBanBlockLogDetail: async (id: string): Promise<ModerationLogDetail> =>
        await this.instance.get(
          `moderation/api/v1/moderation_decision_logs/${id}`,
          {
            withCredentials: true,
          }
        ),
      updateAppeal: async (data: any, appealId: string): Promise<any> =>
        await this.instance.patch(
          `/moderation/api/v1/appeals/${appealId}/`,
          data,
          {
            withCredentials: true,
          }
        ),
      getModeratorPermissions: async (
        community?: string
      ): Promise<ModeratorPermissions> =>
        await this.instance.get(`communities/api/v1/moderator_permissions`, {
          params: {
            ...(community && { community }),
            moderatable_only: true,
          },
          withCredentials: true,
        }),
      searchUsers: async (
        searchText: string,
        communityId?: string,
        noModerator?: boolean
      ): Promise<CustomerProfile[]> => {
        return await this.instance.get(
          `/customers/api/v1/customer_usernames?search=${searchText}`,
          {
            withCredentials: true,
            params: {
              search: searchText,
              exclude_self: true,
              ...(communityId && { not_moderator_in_communities: communityId }),
              ...(noModerator && { not_any_moderator: noModerator }),
            },
          }
        );
      },
    };
  }

  polls() {
    return {
      createPoll: async (data: any): Promise<Poll> =>
        await this.instance.post("/posts/api/v1/polls/", data, {
          withCredentials: true,
        }),
      getPollsByPostId: async (postId: string): Promise<Polls> =>
        await this.instance.get(`/posts/api/v1/polls/`, {
          params: {
            post: postId,
          },
        }),
      editPoll: async (pollId: string, data: any): Promise<Polls> =>
        await this.instance.put(`/posts/api/v1/polls/${pollId}/`, data, {
          withCredentials: true,
        }),
      createPollVote: async (data: any): Promise<any> =>
        await this.instance.post("/posts/api/v1/poll_votes/", data, {
          withCredentials: true,
        }),
      createPollOption: async (data: any): Promise<void> =>
        await this.instance.post(`/posts/api/v1/poll_options/`, data, {
          withCredentials: true,
        }),
      editPollOption: async (optionId: string, data: any): Promise<void> =>
        await this.instance.put(
          `/posts/api/v1/poll_options/${optionId}/`,
          data,
          { withCredentials: true }
        ),
      deletePollOption: async (optionId: string): Promise<void> =>
        await this.instance.delete(`/posts/api/v1/poll_options/${optionId}/`, {
          withCredentials: true,
        }),
    };
  }

  reaction() {
    return {
      getReactions: async (
        ids: string[],
        type: "post" | "comment" = "post",
        config?: AxiosRequestConfig
      ): Promise<ReactionsResult[]> => {
        return this.instance.get("reacts/api/v1/reactions/", {
          params: {
            object_ids: ids.toString(),
            object_type: type,
          },
          ...config,
        });
      },
      createReaction: async (
        data: ReactionRequestBody
      ): Promise<ReactionResult> =>
        this.instance.post("reacts/api/v1/reactions/", data, {
          withCredentials: true,
        }),
      changeReaction: async (reactionId: string, data: ReactionRequestBody) =>
        this.instance.put(`reacts/api/v1/reactions/${reactionId}/`, data, {
          withCredentials: true,
        }),
      deleteReaction: async (reactionId: string) =>
        this.instance.delete(`reacts/api/v1/reactions/${reactionId}/`, {
          withCredentials: true,
        }),
    };
  }

  community() {
    return {
      getCommunities: async (): Promise<CommunitiesResult> =>
        await this.instance.get("communities/api/v1/communities/"),
      getSubCategories: async (
        community: string
      ): Promise<SubCategoriesResult> =>
        await this.instance.get("communities/api/v1/sub_categories", {
          params: { community },
          withCredentials: false,
        }),
      getSubCategoryById: async (subCategoryId: string): Promise<SubCategory> =>
        await this.instance.get(
          `communities/api/v1/sub_categories/${subCategoryId}`
        ),
      getModeratorsHighestPosition: async (): Promise<any> =>
        await this.instance.get(
          `communities/api/v1/highest_moderator_position`,
          { withCredentials: true }
        ),
      getIsCommunityModerator: async (
        customer: string,
        community: string
      ): Promise<{ isModeratorOfCommunity: boolean }> =>
        await this.instance.get(`communities/api/v1/is_moderator?customer`, {
          params: {
            customer,
            community,
          },
          withCredentials: true,
        }),
      banCustomerByModerator: async (
        userId: string,
        banDetails: BanUserRequest
      ): Promise<ActiveBan[]> => {
        const { isBlanketBan, endTime, reason, communities } = banDetails;

        const banList = [];
        if (isBlanketBan) {
          banList.push({
            community: "",
            customer: userId,
            is_blanket_banned: isBlanketBan,
            end_time: endTime,
            reason: reason,
          });
        } else {
          communities.forEach((community) => {
            banList.push({
              community: community,
              customer: userId,
              is_blanket_banned: isBlanketBan,
              end_time: endTime,
              reason: reason,
            });
          });
        }

        return await this.instance.post(`moderation/api/v1/bans/`, banList, {
          withCredentials: true,
        });
      },
      unbanCustomerByModerator: async (id: string) =>
        await this.instance.delete(`/moderation/api/v1/bans/${id}/`, {
          withCredentials: true,
        }),
      getCustomerBanStatus: async (
        customerId: string,
        communityId?: string | null
      ): Promise<CustomerBannedResult[]> =>
        await this.instance.get(
          `moderation/api/v1/bans/?customer=${customerId}`,
          {
            params: {
              community: communityId,
            },
            withCredentials: true,
          }
        ),
      getUnbannedCommunities: async (
        customerId: string,
        moderateOnly: boolean
      ): Promise<UnbannedCommunitiesResult> =>
        await this.instance.get(
          `moderation/api/v1/not_banned_communities?customer=${customerId}&moderatable_only=${moderateOnly}`,
          {
            withCredentials: true,
          }
        ),
      muteCustomer: async (customerId: string): Promise<any> =>
        await this.instance.post(
          `customers/api/v1/customer_relations/`,
          {
            related_customer: customerId,
            relation_type: "muted",
          },
          {
            withCredentials: true,
          }
        ),
      unmuteCustomer: async (customerId: string): Promise<any> =>
        await this.instance.delete(
          `customers/api/v1/customer_relations/${customerId}/?relation_type=muted`,
          {
            withCredentials: true,
          }
        ),
      getMuteStatus: async (
        customerIds: Array<string>
      ): Promise<CustomerMuteInfo[]> => {
        const mutedIdString = customerIds.join(",");

        const customerMuteResults = await this.instance.get<
          CustomerMuteResults,
          CustomerMuteResults
        >(`/customers/api/v1/customer_relations/`, {
          withCredentials: true,
          params: {
            related_customers: mutedIdString,
            relation_type: "muted",
          },
        });

        return Promise.resolve(customerMuteResults?.results);
      },
    };
  }
}

const createPostServiceClient = () => {
  return new PostServiceClient();
};

export const getPostService = (accessToken?: string) => {
  if (accessToken) {
    return new PostServiceClient(accessToken);
  }

  const _postServiceClient = postServiceClient ?? createPostServiceClient();

  if (!postServiceClient) postServiceClient = _postServiceClient;

  return _postServiceClient;
};

/**
 * It creates a PostService Client instance and returns it
 */
export const usePostService = () => {
  const store = useMemo(() => getPostService(), []);
  return store;
};

export type {
  CommunitiesResult,
  PostsResult,
  SubCategoriesResult,
  TagsResult,
  PaginatedResult,
};
