import type { SerializedEditorState } from "lexical";

export interface UploadImageType {
  createdAt: string;
  id: string;
  objectId: string;
  objectType: string;
  s3File: string;
  updatedAt: string;
}

export interface Content {
  body: Body;
}

export interface PostConfig {
  isPinned?: boolean;
  isPremium?: boolean;
  isNotificationPaused?: boolean;
}

export interface Body {
  editorJson: SerializedEditorState;
  htmlString: string;
}

export interface Score {
  contentId: string;
  contentType: string;
  createdAt: string;
  id: string;
  reactScore: number;
  replyScore: number;
  score: number;
  updatedAt: string;
}

export interface Post {
  id: string;
  title: string;
  slug: string;
  content: Content;
  config: PostConfig;
  customer: string;
  subCategory: string;
  state: string;
  createdAt: string;
  updatedAt: string;
  lastActivityAt: string;
  tags: Tag[];
  score: Score;
}

export interface CustomerPost extends Omit<Post, "customer"> {
  customer?: CustomerShortProfile;
  reactions?: PostReactions;
}

export interface ArticlePost {
  articleId: string;
  post: Post;
  community: string;
}

export interface CustomerShortProfile {
  avatar: string;
  id: string;
  username: string;
  createdAt?: string;
  status?: "premium" | "deleted" | "muted";
}

export interface Tag {
  id: string;
  name: string;
}

export interface Community {
  id: string;
  name: string;
  slug: string;
  organization: string;
  createdAt: string;
  updatedAt: string;
}

export interface SubCategory {
  id: string;
  name: string;
  slug: string;
  community: string;
  type: SubCategoryType;
  createdAt: string;
  updatedAt: string;
  moderatorOnly: boolean;
}

export enum SubCategoryType {
  normal = "normal",
  fixedTitle = "fixed_title",
}

export enum PostTypes {
  draft = "draft",
  active = "active",
  blocked = "blocked",
}

export enum ImageUploadTypes {
  post = "post",
  comment = "commment",
}

export enum CommentParent {
  comment = "comment",
  post = "post",
}

export interface ChildrenComments {
  count: number;
  results: Comment[];
}
export interface Comment {
  id: string;
  state: PostTypes;
  content: Body;
  parentType: CommentParent;
  parentId: string;
  rootype: string;
  rootId: string;
  customer: string;
  customerName?: string;
  commentDay?: string;
  isHidden?: boolean;
  profilePicture?: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  score: Score;
  childrenComments: ChildrenComments;
  config: { isNotificationPaused?: boolean };
}

export interface CustomerComment
  extends Omit<Comment, "customer" | "childrenComments"> {
  customer?: CustomerShortProfile;
  reactions?: PostReactions;
  childrenComments: CustomerChildrenComments;
}

export interface CustomerChildrenComments
  extends Omit<ChildrenComments, "results"> {
  results: CustomerComment[];
}

export interface Comments {
  count: number;
  next: string;
  previous: string | null;
  results: Comment[];
}

export interface PostReactions {
  objectId: string;
  objectType: string;
  reactions: Reaction[];
}

export interface Reaction {
  name: string;
  count: number;
  youReacted: string;
}

export interface ReactionDetail {
  id: string;
  emoji: string;
  objectType: "post" | "comment";
  objectId: string;
  customer: string;
  createdAt: string;
  updatedAt: string;
  customerProfile: CustomerShortProfile;
}

export interface ReactionDetails {
  count: number;
  next: string;
  previous: string | null;
  results: ReactionDetail[];
}

export enum PostOrder {
  latest = "-last_activity_at",
  popular = "score",
  new = "-created_at",
  relevant = "relevant",
}

export enum PostOrderOptions {
  "score" = "Popular",
  "-created_at" = "New for you",
  "-last_activity_at" = "Latest",
  "relevant" = "Most Relevant",
}

export interface PollOption {
  createdAt: string;
  id: string;
  option: Record<string, string>;
  optionText: string;
  poll: string;
  updatedAt: string;
  voteCount: number;
  youVoted: null | boolean;
}

interface PollContent {
  body: Body;
  question: string;
}
export interface Poll {
  id: string;
  content: PollContent;
  post: string;
  endPollDatetime: null | string;
  isPollClosed: boolean;
  options: PollOption[];
  createdAt: string;
  updatedAt: string;
}

export interface Polls {
  count: number;
  next: string;
  previous: string | null;
  results: Poll[];
}

export interface CustomerBannedResult {
  community: string;
  customer: string;
  isBlanketBanned: boolean;
  endTime: string | null;
  reason: string;
  id: string;
}

export interface CustomerModeratorResult {
  isModeratorOfCommunity: boolean;
}

export interface UnbannedCommunitiesResult {
  unbannedCommunities: string[];
  isBlanketBanned: boolean;
}

export interface ModeratorPermissions {
  canAccessModerationPanel: boolean;
  canAccessModerationPanelOfCommunity: boolean;
  canBanOrUnbanInCommunity: boolean;
  canBlanketBanOrUnban: boolean;
  canBlockOrUnblockInCommunity: boolean;
  canPostInModeratorOnlySubcategory: boolean;
  canPinOrUnpinInCommunity: boolean;
  canEditOwnPostInCommunity: boolean;
  canMarkOrUnmarkPostAsPremium: boolean;
}

export interface ModeratorPermissionsResult {
  [key: string]: ModeratorPermissions;
}

export interface CustomerProfile {
  id: string;
  email: string;
  username: string;
  organization: string;
  createdAt: string;
}

export interface ModeratorsResult {
  count: number;
  next: string;
  previous: string | null;
  results: Moderator[];
}

export interface Customer {
  id: string;
  email: string;
  username: string;
  organization: string;
  createdAt: string;
}

export interface Moderator {
  community: string;
  type: string;
  updatedAt: string;
  customer: Customer;
}

export interface ModeratorLog {
  id: string;
  type: string;
  community: string;
  affectedCustomer: Pick<CustomerProfile, "id" | "username">;
  moderator: Pick<CustomerProfile, "id" | "username">;
  appealState: string;
  createdAt: string;
}

export interface ModeratorsLogsResult {
  count: number;
  next: string;
  previous: string | null;
  results: ModeratorLog[];
}

interface AppealContent {
  id: string;
  defense: string;
  state: string;
}

interface Violations {
  bans: number;
  blocks: number;
  total: number;
}

export interface ModerationLogDetail {
  id: string;
  type: string;
  reason: string;
  affectedCustomer: Pick<CustomerProfile, "id" | "username">;
  community: string;
  affectedObjectType?: string;
  affectedObjectId?: string;
  isBlanketBanned?: boolean;
  endTime: string;
  duration: string;
  moderator: Pick<CustomerProfile, "id" | "username">;
  createdAt: string;
  numberOfViolations: Violations;
  appeal: AppealContent;
}

export interface BanUserRequest {
  reason: string;
  isBlanketBan: boolean;
  endTime: string;
  communities: string[];
  customer?: CustomerShortProfile;
}

export interface ModerationCustomerProfile {
  id: string;
  username: string;
  email: string;
}

export interface BanOrBlockModerationLog {
  id: string;
  type: string;
  appealState: string;
  appealId: string;
  affectedCustomer: Pick<CustomerProfile, "id" | "username" | "email">;
  moderator: Pick<CustomerProfile, "id" | "username" | "email">;
  createdAt: "2023-10-17T10:46:45.992247Z";
}

export interface ModerationPost {
  id: string;
  slug: string;
  title: string;
  communityId: string;
  customer: ModerationCustomerProfile;
  moderationDecisionLog: BanOrBlockModerationLog;
  subCategory: Pick<SubCategory, "id" | "name" | "community" | "slug">;
  state: string;
  createdAt: string;
  updatedAt: string;
}

export interface ModerationComment {
  id: string;
  customer: ModerationCustomerProfile;
  state: string;
  rootObjectData: {
    slug: string;
    subCategory: Pick<SubCategory, "id" | "name" | "community" | "slug">;
  };
  isDeleted: false;
  parentType: string;
  parentId: string;
  rootType: string;
  rootId: string;
  moderationDecisionLog: BanOrBlockModerationLog;
  createdAt: string;
  updatedAt: string;
  communityId: string;
}

export interface ActiveBan {
  id: string;
  community: string;
  customer: Pick<CustomerProfile, "id" | "username" | "email">;
  isBlanketBanned: boolean;
  endTime: string;
  moderationDecisionLog: BanOrBlockModerationLog;
}

export enum ModeratorPosition {
  SUPER_MODERATOR = "super_moderator",
  COMMUNITY_ADMIN = "community_admin",
  COMMUNITY_MODERATOR = "community_moderator",
}

export type AppealAffectedPost = Omit<
  ModerationPost,
  "moderationDecisionLog" | "customer" | "communityId"
>;

export type AppealAffectedComment = Omit<
  ModerationComment,
  "moderationDecisionLog" | "customer" | "communityId"
>;

export interface AppealLog {
  id: string;
  type: string;
  community: string;
  isBlanketBanned?: boolean;
  endTime: string;
  duration: string;
  affectedCustomer: Pick<CustomerProfile, "id" | "username" | "email">;
  affectedObjectType?: string;
  affectedObjectId?: string;
  affectedContent: AppealAffectedPost | AppealAffectedComment;
  moderator: Pick<CustomerProfile, "id" | "username" | "email">;
  createdAt: string;
}

export interface Appeal {
  id: string;
  moderationDecisionLog: AppealLog;
  customer: string;
  state: string;
  defense: string;
  addressalReason: string;
  addressedAt: string;
  createdAt: string;
}

export interface CustomerMuteInfo {
  relatedCustomer: string;
  relationType: string;
}

export interface CustomerMuteResults {
  count: number;
  next: string | null;
  previous: string | null;
  results: CustomerMuteInfo[];
}

export enum FallbackUsername {
  premium = "PremiumUser",
  deleted = "DeletedUser",
}

export type PostListDensity = "compact" | "expanded";
